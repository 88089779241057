function About(){
    return(
        <div className="about">
           <p>
           Oduko Ositadimma is an Igbo and French language educator, he also is arguably an Igbo language and culture enthusiast, in a unique way he fuels your interest taking you through the journey to learn these languages. He is a language instructor <a className="link-to-pluri" href="https://www.pluritongues.com">@Pluritongues</a> and also works with Alliance Française. He has his 2nd Masters in French as a foreign language in The Université de Franche-Comté Besançon France.
           </p>

           <p>
           With a degree in languages and linguistics (French major), and 1st Masters in French as foreign language. He is a DELF/DALF (an official diploma awarded by the French Ministry of Education to certify the French language competency of the French language) examiner.
           </p>

           <p>
           It is profound how he has honed his Igbo language skills through basic interactions with the elderly, focus group discussions and constant research. Oduko Ositadimma who would prefer to be addressed as "Maàzị̄ ", the Igbo equivalent of "Mr" is armed with a wealth of Igbo language, culture and tradition and seamlessly transfers same to anyone who he comes in contact with in the bid to save our cherished Igbo language from going into extinction.
           </p> 

           <p>
            
           </p>

           <p>
            
           </p>
           
        </div>
    )
}

export default About